import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useWalletAction from './hooks/useWalletAction'
import VerifyPasswordModal from '../modals/auth/transactions/VerifyPasswordModal'
import { Form, Formik } from 'formik'
import { transactionValiation } from './schema'
import { KRW_SYMBOL, WALLET_ACTION } from '../../utils/constants'
import Button from '../form-elements/Button/Button'
import TextField from '../form-elements/TextField/TextField'
import UniqueCodesList from './UniqueCodesList'
import { REACT_ICONS } from '../../utils/icons'
import { formatNumber } from '../../utils/helpers'
import './depositWithdraw.scss'
import { LockedFundProgress } from '../modals/fundProgress/LockedFundProgress'
const DepositWithdraw = ({ isSetting }) => {
  const { t } = useTranslation()
  const {
    showAccNumber,
    setShowAccNumber,
    verifyUserLoading,
    isPasswordModalOpen,
    setIsPasswordModalOpen,
    handleWalletAction,
    handleSubmit,
    data,
    walletActionLoading,
    actionType,
    userDetails,
    setIsToViewAccNumber,
    setData,
    isGetVoucherAvailable,
    setIsGetVoucherAvailable,
    voucherCode,
    setVoucherCode,
    form,
    setForm,
    initialVal,
    isRolloverOpen,
    setIsRollOverOpen
  } = useWalletAction(isSetting)
  const lockedFunds = Number(userDetails?.lockedFunds)
  // Number(userDetails?.userWallet?.nonWithdrawableAmount) +
  // Number(userDetails?.userWallet?.casinoPromotionCash) +
  //  Number(userDetails?.userWallet?.sportsPromotionCash) +
  //  Number(userDetails?.userWallet?.bonusWalletAmount) +
  //  Number(userDetails?.userWallet?.sportsBonusWalletAmount) +
  //  Number(userDetails?.userWallet?.totalPromotionCash)

  useEffect(() => {
    setForm(initialVal)
    setVoucherCode(null)
  }, [actionType])

  const handlePasswordVisibility = () => {
    if (showAccNumber) {
      setShowAccNumber(prev => !prev)
      setIsToViewAccNumber(false)
    } else {
      setIsToViewAccNumber(true)
      setData({ actionSource: WALLET_ACTION.DEPOSIT })
      setIsPasswordModalOpen(true)
    }
  }
  const actualWithdrawalLimit = userDetails?.withdrawlLimit ?? userDetails?.tenantWithdrawlLimit
  const maxAmount = Math.min(actualWithdrawalLimit, userDetails?.withdrawableAmount)

  const renderForm = (type) => {
    const last4Digits = isSetting ? userDetails?.accountNumber?.slice(-4) : userDetails?.adminDetail?.adminAccountNumber?.slice(-4)
    const maskedNumber = isSetting ? last4Digits?.padStart(userDetails?.accountNumber?.length, '*') : last4Digits?.padStart(userDetails?.adminDetail?.adminAccountNumber?.length, '*')
    return (
      <div className='deposit-withdraw'>
        <Formik
          initialValues={form}
          validationSchema={transactionValiation(t, actionType, userDetails?.withdrawableAmount)}
          onSubmit={(values, formActions) => handleWalletAction(type, values, formActions)}
          validateOnChange
          validateOnBlur
          enableReinitialize
          key={type}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setFieldTouched
          }) => {
            return (
              <Form className=' signup-form1 d-flex flex-column gap-3'>
                <div className=' deposit-withdraw--parent  '>
                  {!isSetting && (
                    <div className='d-flex gap-2 align-items-center acc-number'>
                      <div>
                        {t('accountNumberLabel')}:
                      </div>
                      <div>
                        {showAccNumber
                          ? userDetails?.adminDetail?.adminAccountNumber
                          : maskedNumber}
                      </div>
                      <Button variant='primary' title={showAccNumber ? t('hide') : t('show')} type='button' onClick={handlePasswordVisibility} className='ms-auto neo-active' />
                    </div>
                  )}
                  <div className=' d-flex  flex-column input-label'>

                    <div className='form-group'>
                      <TextField
                        label={t('currency')}
                        name='currency'
                        // value={form.currency}
                        defaultValue={KRW_SYMBOL}
                        readOnly
                      />
                    </div>
                    {isSetting && (
                      <>
                        <div className='form-group'>
                          <TextField
                            label={t('bankNameLabel')}
                            name='bankName'
                            value={userDetails?.bankName || t('na')}
                            defaultValue={userDetails?.bankName || t('na')}
                            readOnly
                          />
                        </div>
                        <div className='form-group'>
                          <TextField
                            label={t('accountNumberLabel')}
                            name='accountNumber'
                            value={userDetails?.accountNumber}
                            defaultValue={userDetails?.accountNumber}
                            readOnly
                          />
                        </div>

                      </>
                    )}

                  </div>

                  <div className='form-group'>
                    <TextField
                      isCurrencyField
                      label={t('amount')}
                      name='amount'
                      value={form.amount}
                      onChange={(val) => {
                        setFieldValue('amount', val)
                        setForm(prev => ({ ...prev, amount: val }))
                      }}
                      onBlur={handleBlur}
                      placeholder={t('enterAmountPlaceholder')}
                    />

                  </div>
                  {isSetting && (
                    <>
                      <div className='amount-tabs'>
                        {[
                          { label: 'Min', value: 10000, isDisabled: false },
                          { label: '100000', value: 100000, isDisabled: maxAmount < 100000 },
                          { label: '500000', value: 500000, isDisabled: maxAmount < 500000 },
                          { label: '1000000', value: 1000000, isDisabled: maxAmount < 1000000 },
                          { label: '5000000', value: 5000000, isDisabled: maxAmount < 5000000 },
                          { label: 'Max', value: maxAmount, isDisabled: false }
                        ]?.map((item) => {
                          return (
                            <div
                              onClick={() => {
                                if (!item.isDisabled) {
                                  setFieldValue('amount', item.value)
                                  setForm((prev) => ({ ...prev, amount: item.value }))
                                }
                              }}
                              className={`amount-tab-btn ${item.isDisabled ? 'disabled' : ''} ${item.value === form.amount ? 'active' : ''}`}
                              key={item.value}
                              role='button'
                            >
                              {item.label === 'Min' || item.label === 'Max' ? t(item.label) : formatNumber(item.value)}
                            </div>

                          )
                        })}

                      </div>
                      <div className='d-flex align-items-center gap-2 justify-content-end mt-3'>
                        <div className='d-flex align-items-center gap-1'>
                          <span>{t('Min')}:</span>
                          <span className='text-danger text-sm'>{formatNumber(10000)}</span>
                        </div>
                      </div>
                      <div className='d-flex align-items-center gap-2 justify-content-between mt-1'>
                        <div className='d-flex align-items-center gap-1'>
                          <span>{t('available')}:</span>
                          <span className='text-sm'>{formatNumber(userDetails?.withdrawableAmount)} {KRW_SYMBOL}</span>
                        </div>
                        <div className='d-flex align-items-center gap-1'>
                          <span className='locked-fund' role='button' onClick={() => setIsRollOverOpen(true)}>{t('lockedFunds')}:</span>
                          <span className='text-sm'>{formatNumber(lockedFunds)} {KRW_SYMBOL}</span>
                        </div>
                      </div>

                    </>
                  )}
                  {type === WALLET_ACTION.DEPOSIT && !isSetting && (
                    <>
                      {voucherCode && (
                        <div className='d-flex align-items-center gap-2 py-2 '>
                          <div className='text-success  text-sm'>
                            <div>{voucherCode?.voucher} ,  {t('couponIsApplied')}</div>
                          </div>
                          <div
                            onClick={() => {
                              setVoucherCode(null)
                            }} style={{ fontSize: 30, lineHeight: 1 }} className='text-danger cursor-pointer'
                          >{REACT_ICONS.close}
                          </div>
                        </div>
                      )}
                      <div
                        onClick={() => {
                          if (form?.amount !== '' && form?.currency) {
                            setIsGetVoucherAvailable(prev => !prev)
                          }
                        }}
                        role='button'
                        className={`mt-2 d-inline-block view-all-vouchers ${form?.amount === '' || !form?.currency || Object.keys(errors).length ? 'disabled' : ''}`}
                      >
                        {t('applyCoupon')}
                      </div>
                    </>
                  )}

                </div>
                <div className='footer-modal'>
                  <ul>

                    <li>
                      <Button variant='primary' loading={walletActionLoading} title={walletActionLoading ? t('loading') : isSetting ? t('requestWithdraw') : t('requestDeposit')} type='submit' />

                    </li>
                  </ul>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }
  const memoiZedForm = useMemo(() => renderForm(actionType), [actionType, showAccNumber, walletActionLoading, voucherCode?.voucher, form, t, userDetails])
  return (
    <>
      <div className='voucher-slide-container'>
        <div className={`${isGetVoucherAvailable ? 'active' : ''} voucher-slider `}>
          <div>
            <div className='tab-content' id='pills-tabContent'>
              <div className='tab-pane fade show active' id='pills-deposit' role='tabpanel' aria-labelledby='pills-deposit-tab'>
                {memoiZedForm}
              </div>
              <div className='tab-pane fade' id='pills-withdraw' role='tabpanel' aria-labelledby='pills-withdraw-tab'>
                {memoiZedForm}
              </div>
            </div>
          </div>
          {
            isGetVoucherAvailable && (
              <div>
                <UniqueCodesList setIsGetVoucherAvailable={setIsGetVoucherAvailable} voucherCode={voucherCode} setVoucherCode={setVoucherCode} form={form} setForm={setForm} />
              </div>
            )
          }
        </div>
      </div>
      <VerifyPasswordModal
        type={data?.actionSource}
        isOpen={isPasswordModalOpen}
        handleClose={() => setIsPasswordModalOpen(false)}
        onSubmit={handleSubmit}
        loading={verifyUserLoading}
      />
      {isRolloverOpen && <LockedFundProgress isOpen={isRolloverOpen} handleCloseModal={() => setIsRollOverOpen(false)} />}
    </>
  )
}

export default DepositWithdraw
