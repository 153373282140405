import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { verifyUserStart } from '../../../store/redux-slices/login'
import { getUserDetailsStart } from '../../../store/redux-slices/user'
import { WALLET_ACTION } from '../../../utils/constants'
const initialVal = {
  amount: '',
  currency: 'KRW'
}
const useWalletAction = (isSetting) => {
  const [isRolloverOpen, setIsRollOverOpen] = useState(false)
  const { isWalletActionOpen } = useSelector((state) => state.login)
  const [form, setForm] = useState(initialVal)
  const { userDetails, currencies } = useSelector((state) => state.user)
  const { verifyUserLoading } = useSelector(state => state.login)
  const { walletActionLoading } = useSelector(state => state.transactions)
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
  const [isToViewAccNumber, setIsToViewAccNumber] = useState(false)
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false)
  const [showAccNumber, setShowAccNumber] = useState(false)
  const [data, setData] = useState({})
  const [actionType, setActionType] = useState(isSetting ? WALLET_ACTION.WITHDRAW : WALLET_ACTION.DEPOSIT)
  const dispatch = useDispatch()
  const [isGetVoucherAvailable, setIsGetVoucherAvailable] = useState(false)
  const [voucherCode, setVoucherCode] = useState(null)
  const verifyUserData = {
    type: data.actionSource,
    username: userDetails?.username,
    data: data,
    closeModal: () => {
      setIsPasswordModalOpen(false)
      if (isToViewAccNumber) {
        setShowAccNumber(true)
      }
    },
    handleReset: () => {
      setVoucherCode(null)
      setIsGetVoucherAvailable(false)
      setForm(initialVal)
    }
  }

  const handleSubmit = (values) => {
    dispatch(verifyUserStart({ ...verifyUserData, password: values.password }))
  }

  const handleWalletAction = (type, values, formActions) => {
    setData({ ...values, amount: Number(values.amount), actionSource: type, isVerified: true, ...(actionType === WALLET_ACTION.DEPOSIT && voucherCode && { uniqueCodeId: voucherCode ? voucherCode?.voucherId : null }) })
    setIsPasswordModalOpen(true)
  }

  useEffect(() => {
    dispatch(getUserDetailsStart(window.location.pathname))
  }, [])

  return {
    showAccNumber,
    setShowAccNumber,
    handleWalletAction,
    isPasswordModalOpen,
    setIsPasswordModalOpen,
    handleSubmit,
    verifyUserLoading,
    currencies,
    userDetails,
    data,
    actionType,
    setActionType,
    walletActionLoading,
    isVerifyModalOpen,
    setIsVerifyModalOpen,
    setIsToViewAccNumber,
    setData,
    isGetVoucherAvailable,
    setIsGetVoucherAvailable,
    voucherCode,
    setVoucherCode,
    form,
    setForm,
    initialVal,
    isWalletActionOpen,
    isRolloverOpen,
    setIsRollOverOpen
  }
}

export default useWalletAction
