// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("ProximaNova-Regular.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("ProximaNova-Regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("ProximaNova-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("ProximaNova-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("ProximaNova-Regular.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "ProximaNova";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("svg");
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("eot");
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2");
  font-weight: normal;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/fonts/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,0DAAiD;EACjD,0DAAiD;EACjD,+DAAsD;EACtD,2DAAmD;EACnD,4DAAqD;EACrD,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: \"ProximaNova\";\n  src: url(\"ProximaNova-Regular.svg\") format(\"svg\");\n  src: url(\"ProximaNova-Regular.eot\") format(\"eot\");\n  src: url(\"ProximaNova-Regular.ttf\") format(\"truetype\");\n  src: url(\"ProximaNova-Regular.woff\") format(\"woff\");\n  src: url(\"ProximaNova-Regular.woff2\") format(\"woff2\");\n  font-weight: normal;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
