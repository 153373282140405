import { createSlice } from '@reduxjs/toolkit'

const bonusSlice = createSlice({
  name: 'bonus',
  initialState: {
    generalBonusLoading: false,
    paybackBonusLoading: false,
    dashboardBonusLoading: false,
    rackbackBonusLoading: false,
    generalBonus: {},
    dashboardBonus: {},
    paybackBonus: {},
    rackbackBonus: {},
    rackbackClaimLoading: false,
    paybackClaimLoading: false,
    rollOverType: [],
    rollOverData: {},
    rollOverDataLoading: false,
    rollOverTypeLoading: false,
    userBonusTransaction: '',
    userBonusCount: '',
    userBonusTransactionLoading: false,
    userBonusCountLoading: false,
    sportsPaybackBonusLoading: false,
    sportsPaybackClaimBonusLoading: false,
    sportsPaybackBonus: {},
    promotionConfigs: {},
    promotionConfigsLoading: false

  },
  reducers: {
    getPromotionConfigsStart: (state) => ({
      ...state,
      promotionConfigsLoading: true
    }),
    getPromotionConfigsSuccess: (state, { payload }) => ({
      ...state,
      promotionConfigsLoading: false,
      promotionConfigs: payload
    }),
    getPromotionConfigsFailure: (state) => ({
      ...state,
      promotionConfigsLoading: false
    }),
    getUserBonusCountStart: (state) => ({
      ...state,
      userBonusCountLoading: true
    }),
    getUserBonusCountSuccess: (state, { payload }) => ({
      ...state,
      userBonusCountLoading: false,
      userBonusCount: payload
    }),
    getUserBonusCountFailure: (state) => ({
      ...state,
      userBonusCountLoading: false
    }),
    getUserBonusTransactionStart: (state) => ({
      ...state,
      userBonusTransactionLoading: true
    }),
    getUserBonusTransactionSuccess: (state, { payload }) => ({
      ...state,
      userBonusTransactionLoading: false,
      userBonusTransaction: payload
    }),
    getUserBonusTransactionFailure: (state) => ({
      ...state,
      userBonusTransactionLoading: false
    }),
    getRollOverTypeStart: (state) => ({
      ...state,
      rollOverTypeLoading: true
    }),
    getRollOverTypeSuccess: (state, { payload }) => ({
      ...state,
      rollOverTypeLoading: false,
      rollOverType: payload
    }),
    getRollOverTypeFailure: (state) => ({
      ...state,
      rollOverTypeLoading: false
    }),
    getRollOverDataStart: (state) => ({
      ...state,
      rollOverDataLoading: true
    }),
    getRollOverDataSuccess: (state, { payload }) => ({
      ...state,
      rollOverDataLoading: false,
      rollOverData: payload
    }),
    getRollOverDataFailure: (state) => ({
      ...state,
      rollOverDataLoading: false,
      rollOverData: {}
    }),
    getGeneralBonusStart: (state) => ({
      ...state,
      generalBonusLoading: true
    }),
    getGeneralBonusSuccess: (state, { payload }) => ({
      ...state,
      generalBonusLoading: false,
      generalBonus: payload
    }),
    getGeneralBonusFailure: (state) => ({
      ...state,
      generalBonusLoading: false
    }),
    claimRackbackBonusStart: (state) => ({
      ...state,
      rackbackClaimLoading: true
    }),
    claimRackbackBonusComplete: (state, { payload }) => ({
      ...state,
      rackbackClaimLoading: false
    }),
    claimGeneralBonusStart: (state) => ({
      ...state,
      generalClaimLoading: true
    }),
    claimGeneralBonusComplete: (state, { payload }) => ({
      ...state,
      generalClaimLoading: false
    }),
    claimPaybackBonusStart: (state) => ({
      ...state,
      paybackClaimLoading: true
    }),
    claimPaybackBonusComplete: (state, { payload }) => ({
      ...state,
      paybackClaimLoading: false
    }),

    getRackbackBonusStart: (state) => ({
      ...state,
      rackbackBonusLoading: true
    }),
    getRackbackBonusSuccess: (state, { payload }) => ({
      ...state,
      rackbackBonusLoading: false,
      rackbackBonus: payload
    }),
    getRackbackBonusFailure: (state) => ({
      ...state,
      rackbackBonusLoading: false
    }),
    getPaybackBonusStart: (state) => ({
      ...state,
      paybackBonusLoading: true
    }),
    getPaybackBonusSuccess: (state, { payload }) => ({
      ...state,
      paybackBonusLoading: false,
      paybackBonus: payload
    }),
    getPaybackBonusFailure: (state) => ({
      ...state,
      paybackBonusLoading: false
    }),
    getDashboardBonusStart: (state) => ({
      ...state,
      dashboardBonusLoading: true
    }),
    getDashboardBonusSuccess: (state, { payload }) => ({
      ...state,
      dashboardBonusLoading: false,
      dashboardBonus: payload
    }),
    getDashboardBonusFailure: (state) => ({
      ...state,
      dashboardBonusLoading: false
    }),
    getSportsPaybackBonusStart: (state) => ({
      ...state,
      sportsPaybackBonusLoading: true
    }),
    getSportsPaybackBonusSuccess: (state, { payload }) => ({
      ...state,
      sportsPaybackBonusLoading: false,
      sportsPaybackBonus: payload
    }),
    getSportsPaybackBonusFailure: (state) => ({
      ...state,
      sportsPaybackBonusLoading: false
    }),
    getSportPaybackClaimBonusStart: (state) => ({
      ...state,
      sportsPaybackClaimBonusLoading: true
    }),
    getSportPaybackClaimBonusSuccess: (state, { payload }) => ({
      ...state,
      sportsPaybackClaimBonusLoading: false
    })

  }
})
export const {
  getSportsPaybackBonusStart,
  getSportsPaybackBonusSuccess,
  getSportsPaybackBonusFailure,
  getSportPaybackClaimBonusStart,
  getSportPaybackClaimBonusSuccess,
  getGeneralBonusStart,
  getGeneralBonusSuccess,
  getGeneralBonusFailure,
  getRackbackBonusStart,
  getRackbackBonusSuccess,
  getRackbackBonusFailure,
  getPaybackBonusStart,
  getPaybackBonusSuccess,
  getPaybackBonusFailure,
  getDashboardBonusStart,
  getDashboardBonusSuccess,
  getDashboardBonusFailure,
  claimRackbackBonusStart,
  claimRackbackBonusComplete,
  claimPaybackBonusStart,
  claimPaybackBonusComplete,
  getRollOverTypeStart,
  getRollOverTypeSuccess,
  getRollOverTypeFailure,
  getRollOverDataStart,
  getRollOverDataSuccess,
  getRollOverDataFailure,
  getUserBonusCountStart,
  getUserBonusCountSuccess,
  getUserBonusCountFailure,
  getUserBonusTransactionStart,
  getUserBonusTransactionSuccess,
  getUserBonusTransactionFailure,
  claimGeneralBonusStart,
  claimGeneralBonusComplete,
  getPromotionConfigsStart,
  getPromotionConfigsSuccess,
  getPromotionConfigsFailure
} = bonusSlice.actions

export default bonusSlice.reducer
