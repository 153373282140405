// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.locked-bonus-detail .content-wrapper {
  min-height: 300px;
}
.locked-bonus-detail .content-wrapper .overview-wrapper .scrollable-content {
  height: 300px;
  overflow-y: auto;
  padding-right: 10px;
}
.locked-bonus-detail .content-wrapper .overview-wrapper .header {
  padding: 14px 10px;
  border-radius: 6px;
  border-bottom: 1px solid var(--borderColor);
  background-color: var(--bgSupportSecondary);
}
.locked-bonus-detail .content-wrapper .overview-wrapper .header span {
  font-size: 14px;
}
.locked-bonus-detail .content-wrapper .overview-wrapper .single-info {
  padding: 14px 10px;
  border-bottom: 1px solid var(--borderColor);
}
.locked-bonus-detail .content-wrapper .overview-wrapper .single-info:last-child {
  border-bottom: none;
}
.locked-bonus-detail .content-wrapper .overview-wrapper .single-info .text-muted {
  font-size: 13px;
}
.locked-bonus-detail .content-wrapper .overview-wrapper .single-info .amount {
  text-align: end;
  font-size: 14px;
  font-weight: 500;
}
.locked-bonus-detail .refresh-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid var(--borderColor);
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/fundProgress/lockedFund.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAGM;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;AADR;AAIM;EACE,kBAAA;EACA,kBAAA;EACA,2CAAA;EACA,2CAAA;AAFR;AAIQ;EACE,eAAA;AAFV;AAMM;EACE,kBAAA;EACA,2CAAA;AAJR;AAMQ;EACE,mBAAA;AAJV;AAQQ;EACE,eAAA;AANV;AASQ;EACE,eAAA;EACA,eAAA;EACA,gBAAA;AAPV;AAYE;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAVJ","sourcesContent":[".locked-bonus-detail {\n  .content-wrapper {\n    min-height: 300px;\n\n    .overview-wrapper {\n      .scrollable-content {\n        height: 300px;\n        overflow-y: auto;\n        padding-right: 10px;\n      }\n\n      .header {\n        padding: 14px 10px;\n        border-radius: 6px;\n        border-bottom: 1px solid var(--borderColor);\n        background-color: var(--bgSupportSecondary);\n\n        span {\n          font-size: 14px;\n        }\n      }\n\n      .single-info {\n        padding: 14px 10px;\n        border-bottom: 1px solid var(--borderColor);\n\n        &:last-child {\n          border-bottom: none;\n        }\n\n\n        .text-muted {\n          font-size: 13px;\n        }\n\n        .amount {\n          text-align: end;\n          font-size: 14px;\n          font-weight: 500;\n        }\n      }\n    }\n  }\n  .refresh-icon{\n    width: 40px;\n    height: 40px;\n    cursor: pointer;\n    border-radius: 6px;\n    border: 1px solid var(--borderColor);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
