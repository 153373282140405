import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { deleteCookie, getDocumentCookie, setCookie } from '../../utils/storageUtils'
import { useTranslation } from 'react-i18next'
import { toast } from '../Toast'

export const CookieModal = () => {
  const [show, setShow] = useState(false)
  const [isCookieEnabled, setCookieStatus] = useState(false)
  const cookieConsent = getDocumentCookie('cookie_concent_status')
  const { t } = useTranslation()

  const handleYes = () => {
    acceptCookieConsent()
    setShow(false)
  }

  const handleNo = () => {
    toast(t('cookieDeclinedMessage'), 'error')
  }

  const acceptCookieConsent = () => {
    deleteCookie('cookie_concent_status')
    setCookie('cookie_concent_status', 'true', 365)
    setShow(false)
  }

  useEffect(() => {
    if (window.navigator.cookieEnabled) {
      setCookieStatus(true)
      if (cookieConsent !== 'true') {
        setShow(true)
      }
    } else {
      setCookieStatus(false)
      setShow(true)
    }
  }, [])

  return (
    <Modal
      show={show}
      centered
      className='cookie-consent-modal'
    >
      <Modal.Header className='d-flex flex-column'>
        <img
          src='icon/cookie.png' className='w-25 mb-3' alt='cookie'
        />
        <h4>{t('cookieTitle')}</h4>
      </Modal.Header>

      <Modal.Body>
        {isCookieEnabled ? t('cookieDescription') : 'Cookies not enabled, Kindle enable cookie from browser setting'}
      </Modal.Body>

      <Modal.Footer className='d-flex justify-content-center mt-4'>
        <button className='cookie-consent decline-button' onClick={handleNo}>{t('declineButton')}</button>
        {isCookieEnabled &&
          <button
            className='cookie-consent success-button' onClick={handleYes}
          >{t('acceptButton')}
          </button>}
      </Modal.Footer>
    </Modal>
  )
}
